exports.components = {
  "component---src-components-pages-baza-posrednikow-city-js": () => import("./../../../src/components/pages/baza-posrednikow/city.js" /* webpackChunkName: "component---src-components-pages-baza-posrednikow-city-js" */),
  "component---src-components-pages-baza-posrednikow-company-js": () => import("./../../../src/components/pages/baza-posrednikow/company.js" /* webpackChunkName: "component---src-components-pages-baza-posrednikow-company-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-baza-posrednikow-js": () => import("./../../../src/pages/baza-posrednikow.js" /* webpackChunkName: "component---src-pages-baza-posrednikow-js" */),
  "component---src-pages-cennik-index-js": () => import("./../../../src/pages/cennik/index.js" /* webpackChunkName: "component---src-pages-cennik-index-js" */),
  "component---src-pages-certyfikat-ssl-js": () => import("./../../../src/pages/certyfikat-ssl.js" /* webpackChunkName: "component---src-pages-certyfikat-ssl-js" */),
  "component---src-pages-domena-i-poczta-email-js": () => import("./../../../src/pages/domena-i-poczta-email.js" /* webpackChunkName: "component---src-pages-domena-i-poczta-email-js" */),
  "component---src-pages-google-api-limited-use-disclosure-js": () => import("./../../../src/pages/google-api-limited-use-disclosure.js" /* webpackChunkName: "component---src-pages-google-api-limited-use-disclosure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-21-responsywnych-stron-w-dwa-miesiace-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/21-responsywnych-stron-w-dwa-miesiace/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-21-responsywnych-stron-w-dwa-miesiace-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-500-stron-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/500-stron/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-500-stron-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-amer-ogloszenia-bezposrednie-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/amer-ogloszenia-bezposrednie/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-amer-ogloszenia-bezposrednie-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-certyfikat-ssl-dla-stron-www-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/certyfikat-ssl-dla-stron-www/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-certyfikat-ssl-dla-stron-www-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-co-sie-stalo-z-gumtree-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/co-sie-stalo-z-gumtree/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-co-sie-stalo-z-gumtree-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-co-szoste-biuro-wybralo-imo-z-polecenia-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/co-szoste-biuro-wybralo-imo-z-polecenia/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-co-szoste-biuro-wybralo-imo-z-polecenia-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-cushman-and-wakefield-korzysta-z-imo-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/cushman-and-wakefield-korzysta-z-imo/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-cushman-and-wakefield-korzysta-z-imo-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-eksport-do-portalu-domaza-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/eksport-do-portalu-domaza/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-eksport-do-portalu-domaza-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-import-z-mls-wspon-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/import-z-mls-wspon/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-import-z-mls-wspon-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-integracja-z-portalem-clixs-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/integracja-z-portalem-clixs/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-integracja-z-portalem-clixs-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-integracja-z-portalem-ogis-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/integracja-z-portalem-ogis/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-integracja-z-portalem-ogis-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-lider-nieruchomosci-otodom-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/lider-nieruchomosci-otodom/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-lider-nieruchomosci-otodom-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-nowa-wersja-imo-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/nowa-wersja-imo/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-nowa-wersja-imo-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-nowe-strony-dla-biur-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/nowe-strony-dla-biur-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-nowe-strony-dla-biur-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-nowy-szablon-strony-dla-biura-nieruchomosci-od-imo-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/nowy-szablon-strony-dla-biura-nieruchomosci-od-imo/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-nowy-szablon-strony-dla-biura-nieruchomosci-od-imo-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-ponad-100-responsywnych-stron-w-jeden-rok-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/ponad-100-responsywnych-stron-w-jeden-rok/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-ponad-100-responsywnych-stron-w-jeden-rok-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-pracowite-wakacje-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/pracowite-wakacje/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-pracowite-wakacje-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-responsywna-strona-oraz-system-dla-landmarkapartments-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/responsywna-strona-oraz-system-dla-landmarkapartments/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-responsywna-strona-oraz-system-dla-landmarkapartments-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-strona-www-biura-nieruchomosci-home-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/strona-www-biura-nieruchomosci-home/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-strona-www-biura-nieruchomosci-home-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-szybsze-eksporty-do-otodom-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/szybsze-eksporty-do-otodom/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-szybsze-eksporty-do-otodom-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-tylko-w-imo-generuj-profesjonalne-opisy-nieruchomosci-za-pomoca-ai-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/tylko-w-imo-generuj-profesjonalne-opisy-nieruchomosci-za-pomoca-ai/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-tylko-w-imo-generuj-profesjonalne-opisy-nieruchomosci-za-pomoca-ai-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-wersja-responsywna-szablonu-nr-10-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/wersja-responsywna-szablonu-nr-10/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-wersja-responsywna-szablonu-nr-10-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-wprowadzenie-oplaty-za-eksport-do-gumtree-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/wprowadzenie-oplaty-za-eksport-do-gumtree/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-wprowadzenie-oplaty-za-eksport-do-gumtree-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-wykonalismy-strone-dla-inwestycji-zywiecka-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/wykonalismy-strone-dla-inwestycji-zywiecka/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-wykonalismy-strone-dla-inwestycji-zywiecka-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-zmiana-cen-w-otodom-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/aktualnosci/zmiana-cen-w-otodom/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-aktualnosci-zmiana-cen-w-otodom-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-co-powinienes-wiedziec-aby-zalozyc-biuro-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/poradnik/co-powinienes-wiedziec-aby-zalozyc-biuro-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-co-powinienes-wiedziec-aby-zalozyc-biuro-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-co-zalatwia-posrednik-nieruchomosci-i-jakie-ma-obowiazki-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/poradnik/co-zalatwia-posrednik-nieruchomosci-i-jakie-ma-obowiazki/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-co-zalatwia-posrednik-nieruchomosci-i-jakie-ma-obowiazki-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-czym-charakteryzuje-sie-dobry-posrednik-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/poradnik/czym-charakteryzuje-sie-dobry-posrednik-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-czym-charakteryzuje-sie-dobry-posrednik-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-jak-przygotowac-nieruchomosc-do-sprzedazy-lub-wynajmu-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/poradnik/jak-przygotowac-nieruchomosc-do-sprzedazy-lub-wynajmu/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-jak-przygotowac-nieruchomosc-do-sprzedazy-lub-wynajmu-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-jak-technologia-zmienia-biura-obrotu-nieruchomosciami-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/poradnik/jak-technologia-zmienia-biura-obrotu-nieruchomosciami/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-jak-technologia-zmienia-biura-obrotu-nieruchomosciami-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-jak-zostac-agentem-nieruchomosci-i-na-czym-polega-jego-praca-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/poradnik/jak-zostac-agentem-nieruchomosci-i-na-czym-polega-jego-praca/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-poradnik-jak-zostac-agentem-nieruchomosci-i-na-czym-polega-jego-praca-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-adaptacja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/adaptacja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-adaptacja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-agencja-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/agencja-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-agencja-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-aglomeracja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/aglomeracja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-aglomeracja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-akt-notarialny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/akt-notarialny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-akt-notarialny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-altana-altana-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/altana/altana.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-altana-altana-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-aneks-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/aneks/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-aneks-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-apartament-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/apartament/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-apartament-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-arkada-arkada-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/arkada/arkada.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-arkada-arkada-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-atrium-atrium-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/atrium/atrium.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-atrium-atrium-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-audyt-energetyczny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/audyt-energetyczny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-audyt-energetyczny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-balustrada-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/balustrada/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-balustrada-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-boazeria-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/boazeria/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-boazeria-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-budynek-gospodarczy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/budynek-gospodarczy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-budynek-gospodarczy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-budynek-wielorodzinny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/budynek-wielorodzinny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-budynek-wielorodzinny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-bungalow-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/bungalow/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-bungalow-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-certyfikat-energetyczny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/certyfikat-energetyczny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-certyfikat-energetyczny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-cesja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/cesja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-cesja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-czynsz-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/czynsz/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-czynsz-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-darowizna-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/darowizna/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-darowizna-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-deweloper-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/deweloper/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-deweloper-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dom-jednorodzinny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dom-jednorodzinny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dom-jednorodzinny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dom-pasywny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dom-pasywny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dom-pasywny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-droga-publiczna-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/droga-publiczna/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-droga-publiczna-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzialka-budowlana-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dzialka-budowlana/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzialka-budowlana-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzialka-rekreacyjna-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dzialka-rekreacyjna/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzialka-rekreacyjna-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzialka-rod-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dzialka-rod/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzialka-rod-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dziedziniec-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dziedziniec/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dziedziniec-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzierzawa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/dzierzawa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-dzierzawa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-elewacja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/elewacja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-elewacja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-ewidencja-gruntow-i-budynkow-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/ewidencja-gruntow-i-budynkow/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-ewidencja-gruntow-i-budynkow-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-garaz-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/garaz/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-garaz-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-gospodarstwo-rolne-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/gospodarstwo-rolne/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-gospodarstwo-rolne-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-hipoteka-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/hipoteka/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-hipoteka-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-hipoteka-przymusowa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/hipoteka-przymusowa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-hipoteka-przymusowa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-home-staging-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/home-staging/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-home-staging-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-hydroizolacja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/hydroizolacja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-hydroizolacja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-inwestor-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/inwestor/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-inwestor-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-jednostka-samorzadu-terytorialnego-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/jednostka-samorzadu-terytorialnego/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-jednostka-samorzadu-terytorialnego-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kamienica-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/kamienica/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kamienica-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-karencja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/karencja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-karencja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kredyt-hipoteczny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/kredyt-hipoteczny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kredyt-hipoteczny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kredyt-mieszkaniowy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/kredyt-mieszkaniowy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kredyt-mieszkaniowy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-ksiega-wieczysta-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/ksiega-wieczysta/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-ksiega-wieczysta-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kubatura-budynku-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/kubatura-budynku/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-kubatura-budynku-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-list-intencyjny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/list-intencyjny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-list-intencyjny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-loft-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/loft/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-loft-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-loggia-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/loggia/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-loggia-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-magazyn-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/magazyn/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-magazyn-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-mansarda-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/mansarda/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-mansarda-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-mapa-zasadnicza-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/mapa-zasadnicza/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-mapa-zasadnicza-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-najem-okazjonalny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/najem-okazjonalny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-najem-okazjonalny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-najemca-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/najemca/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-najemca-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-nieruchomosc-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/nieruchomosc/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-nieruchomosc-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-notariusz-rejent-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/notariusz-rejent/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-notariusz-rejent-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-oczko-wodne-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/oczko-wodne/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-oczko-wodne-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-odnawialne-zrodla-energii-oze-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/odnawialne-zrodla-energii-oze/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-odnawialne-zrodla-energii-oze-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-odsetki-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/odsetki/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-odsetki-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-ograniczone-prawo-rzeczowe-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/ograniczone-prawo-rzeczowe/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-ograniczone-prawo-rzeczowe-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-operat-szacunkowy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/operat-szacunkowy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-operat-szacunkowy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-oplata-adiacencka-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/oplata-adiacencka/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-oplata-adiacencka-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-pastwiska-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/pastwiska/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-pastwiska-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-penthouse-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/penthouse/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-penthouse-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-podatek-dochodowy-od-osob-fizycznych-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/podatek-dochodowy-od-osob-fizycznych/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-podatek-dochodowy-od-osob-fizycznych-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-podatek-dochodowy-od-osob-prawnych-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/podatek-dochodowy-od-osob-prawnych/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-podatek-dochodowy-od-osob-prawnych-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-pomieszczenie-gospodarcze-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/pomieszczenie-gospodarcze/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-pomieszczenie-gospodarcze-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-portfenetr-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/portfenetr/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-portfenetr-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-posrednik-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/posrednik-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-posrednik-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-powierzchnia-uzytkowa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/powierzchnia-uzytkowa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-powierzchnia-uzytkowa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-powierzchnia-zabudowy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/powierzchnia-zabudowy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-powierzchnia-zabudowy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-pozwolenie-na-budowe-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/pozwolenie-na-budowe/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-pozwolenie-na-budowe-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-promesa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/promesa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-promesa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-protokol-zdawczo-odbiorczy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/protokol-zdawczo-odbiorczy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-protokol-zdawczo-odbiorczy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-prowizja-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/prowizja/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-prowizja-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rynek-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/rynek-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rynek-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rynek-pierwotny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/rynek-pierwotny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rynek-pierwotny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rynek-wtorny-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/rynek-wtorny/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rynek-wtorny-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rzeczoznawca-majatkowy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/rzeczoznawca-majatkowy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-rzeczoznawca-majatkowy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-sluzebnosc-gruntowa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/sluzebnosc-gruntowa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-sluzebnosc-gruntowa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-sluzebnosc-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/sluzebnosc/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-sluzebnosc-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-spoldzielnia-mieszkaniowa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/spoldzielnia-mieszkaniowa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-spoldzielnia-mieszkaniowa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-stan-deweloperski-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/stan-deweloperski/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-stan-deweloperski-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-stan-surowy-otwarty-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/stan-surowy-otwarty/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-stan-surowy-otwarty-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-stan-surowy-zamkniety-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/stan-surowy-zamkniety/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-stan-surowy-zamkniety-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-standard-deweloperski-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/standard-deweloperski/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-standard-deweloperski-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-suterena-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/suterena/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-suterena-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-swiadectwo-energetyczne-budynku-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/swiadectwo-energetyczne-budynku/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-swiadectwo-energetyczne-budynku-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-taksa-notarialna-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/taksa-notarialna/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-taksa-notarialna-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-umowa-najmu-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/umowa-najmu/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-umowa-najmu-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-umowa-przedwstepna-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/umowa-przedwstepna/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-umowa-przedwstepna-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-uzytkowanie-wieczyste-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/uzytkowanie-wieczyste/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-uzytkowanie-wieczyste-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-warunki-zabudowy-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/warunki-zabudowy/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-warunki-zabudowy-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-wspolnota-mieszkaniowa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/wspolnota-mieszkaniowa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-wspolnota-mieszkaniowa-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-wycena-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/wycena-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-wycena-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-wywlaszczenie-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/wywlaszczenie/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-wywlaszczenie-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zachowek-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/zachowek/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zachowek-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zadatek-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/zadatek/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zadatek-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zaliczka-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/zaliczka/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zaliczka-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zasiedzenie-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/zasiedzenie/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zasiedzenie-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zasiedzenie-nieruchomosci-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/zasiedzenie-nieruchomosci/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zasiedzenie-nieruchomosci-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zdolnosc-kredytowa-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.js?__contentFilePath=/app/src/slownik/zdolnosc-kredytowa/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-js-content-file-path-src-slownik-zdolnosc-kredytowa-index-mdx" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-pomoc-imo-dane-agentow-i-agencji-js": () => import("./../../../src/pages/pomoc/imo/dane-agentow-i-agencji.js" /* webpackChunkName: "component---src-pages-pomoc-imo-dane-agentow-i-agencji-js" */),
  "component---src-pages-pomoc-imo-eksport-oferty-js": () => import("./../../../src/pages/pomoc/imo/eksport-oferty.js" /* webpackChunkName: "component---src-pages-pomoc-imo-eksport-oferty-js" */),
  "component---src-pages-pomoc-imo-grupy-uprawnien-js": () => import("./../../../src/pages/pomoc/imo/grupy-uprawnien.js" /* webpackChunkName: "component---src-pages-pomoc-imo-grupy-uprawnien-js" */),
  "component---src-pages-pomoc-imo-index-js": () => import("./../../../src/pages/pomoc/imo/index.js" /* webpackChunkName: "component---src-pages-pomoc-imo-index-js" */),
  "component---src-pages-pomoc-imo-konfiguracja-eksportu-js": () => import("./../../../src/pages/pomoc/imo/konfiguracja-eksportu.js" /* webpackChunkName: "component---src-pages-pomoc-imo-konfiguracja-eksportu-js" */),
  "component---src-pages-pomoc-imo-konfiguracja-programu-js": () => import("./../../../src/pages/pomoc/imo/konfiguracja-programu.js" /* webpackChunkName: "component---src-pages-pomoc-imo-konfiguracja-programu-js" */),
  "component---src-pages-pomoc-imo-konfiguracja-statusow-js": () => import("./../../../src/pages/pomoc/imo/konfiguracja-statusow.js" /* webpackChunkName: "component---src-pages-pomoc-imo-konfiguracja-statusow-js" */),
  "component---src-pages-pomoc-imo-najczesciej-zadawane-pytania-na-temat-programu-imo-js": () => import("./../../../src/pages/pomoc/imo/najczesciej-zadawane-pytania-na-temat-programu-imo.js" /* webpackChunkName: "component---src-pages-pomoc-imo-najczesciej-zadawane-pytania-na-temat-programu-imo-js" */),
  "component---src-pages-pomoc-imo-ustawienia-listy-js": () => import("./../../../src/pages/pomoc/imo/ustawienia-listy.js" /* webpackChunkName: "component---src-pages-pomoc-imo-ustawienia-listy-js" */),
  "component---src-pages-pomoc-imo-wgrywanie-bazy-ofert-js": () => import("./../../../src/pages/pomoc/imo/wgrywanie-bazy-ofert.js" /* webpackChunkName: "component---src-pages-pomoc-imo-wgrywanie-bazy-ofert-js" */),
  "component---src-pages-pomoc-imo-wymagane-pola-js": () => import("./../../../src/pages/pomoc/imo/wymagane-pola.js" /* webpackChunkName: "component---src-pages-pomoc-imo-wymagane-pola-js" */),
  "component---src-pages-pomoc-imo-zarzadzanie-lokalizacjami-js": () => import("./../../../src/pages/pomoc/imo/zarzadzanie-lokalizacjami.js" /* webpackChunkName: "component---src-pages-pomoc-imo-zarzadzanie-lokalizacjami-js" */),
  "component---src-pages-pomoc-index-js": () => import("./../../../src/pages/pomoc/index.js" /* webpackChunkName: "component---src-pages-pomoc-index-js" */),
  "component---src-pages-pomoc-panel-www-dodawanie-tagow-js": () => import("./../../../src/pages/pomoc/panel-www/dodawanie-tagow.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-dodawanie-tagow-js" */),
  "component---src-pages-pomoc-panel-www-edycja-banera-js": () => import("./../../../src/pages/pomoc/panel-www/edycja-banera.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-edycja-banera-js" */),
  "component---src-pages-pomoc-panel-www-edycja-tresci-js": () => import("./../../../src/pages/pomoc/panel-www/edycja-tresci.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-edycja-tresci-js" */),
  "component---src-pages-pomoc-panel-www-index-js": () => import("./../../../src/pages/pomoc/panel-www/index.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-index-js" */),
  "component---src-pages-pomoc-panel-www-oferty-specjalne-js": () => import("./../../../src/pages/pomoc/panel-www/oferty-specjalne.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-oferty-specjalne-js" */),
  "component---src-pages-pomoc-panel-www-zarzadzanie-plikami-js": () => import("./../../../src/pages/pomoc/panel-www/zarzadzanie-plikami.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-zarzadzanie-plikami-js" */),
  "component---src-pages-pomoc-panel-www-zarzadzanie-podstronami-js": () => import("./../../../src/pages/pomoc/panel-www/zarzadzanie-podstronami.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-zarzadzanie-podstronami-js" */),
  "component---src-pages-pomoc-panel-www-zmiana-danych-dostepowych-js": () => import("./../../../src/pages/pomoc/panel-www/zmiana-danych-dostepowych.js" /* webpackChunkName: "component---src-pages-pomoc-panel-www-zmiana-danych-dostepowych-js" */),
  "component---src-pages-pomoc-regulaminy-js": () => import("./../../../src/pages/pomoc/regulaminy.js" /* webpackChunkName: "component---src-pages-pomoc-regulaminy-js" */),
  "component---src-pages-poradnik-posrednika-js": () => import("./../../../src/pages/poradnik-posrednika.js" /* webpackChunkName: "component---src-pages-poradnik-posrednika-js" */),
  "component---src-pages-program-crm-bezplatna-wersja-demo-js": () => import("./../../../src/pages/program-crm/bezplatna-wersja-demo.js" /* webpackChunkName: "component---src-pages-program-crm-bezplatna-wersja-demo-js" */),
  "component---src-pages-program-crm-cennik-uslug-dodatkowych-js": () => import("./../../../src/pages/program-crm/cennik-uslug-dodatkowych.js" /* webpackChunkName: "component---src-pages-program-crm-cennik-uslug-dodatkowych-js" */),
  "component---src-pages-program-crm-eksport-do-portali-js": () => import("./../../../src/pages/program-crm/eksport-do-portali.js" /* webpackChunkName: "component---src-pages-program-crm-eksport-do-portali-js" */),
  "component---src-pages-program-crm-funkcje-administracja-programem-js": () => import("./../../../src/pages/program-crm/funkcje/administracja-programem.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-administracja-programem-js" */),
  "component---src-pages-program-crm-funkcje-index-js": () => import("./../../../src/pages/program-crm/funkcje/index.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-index-js" */),
  "component---src-pages-program-crm-funkcje-integracja-z-portalami-js": () => import("./../../../src/pages/program-crm/funkcje/integracja-z-portalami.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-integracja-z-portalami-js" */),
  "component---src-pages-program-crm-funkcje-modul-fakturowania-js": () => import("./../../../src/pages/program-crm/funkcje/modul-fakturowania.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-modul-fakturowania-js" */),
  "component---src-pages-program-crm-funkcje-praca-online-js": () => import("./../../../src/pages/program-crm/funkcje/praca-online.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-praca-online-js" */),
  "component---src-pages-program-crm-funkcje-praca-z-baza-klientow-js": () => import("./../../../src/pages/program-crm/funkcje/praca-z-baza-klientow.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-praca-z-baza-klientow-js" */),
  "component---src-pages-program-crm-funkcje-praca-z-baza-ofert-js": () => import("./../../../src/pages/program-crm/funkcje/praca-z-baza-ofert.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-praca-z-baza-ofert-js" */),
  "component---src-pages-program-crm-funkcje-zarzadzanie-biurem-js": () => import("./../../../src/pages/program-crm/funkcje/zarzadzanie-biurem.js" /* webpackChunkName: "component---src-pages-program-crm-funkcje-zarzadzanie-biurem-js" */),
  "component---src-pages-program-crm-index-js": () => import("./../../../src/pages/program-crm/index.js" /* webpackChunkName: "component---src-pages-program-crm-index-js" */),
  "component---src-pages-program-crm-korzysci-dla-posrednika-js": () => import("./../../../src/pages/program-crm/korzysci/dla-posrednika.js" /* webpackChunkName: "component---src-pages-program-crm-korzysci-dla-posrednika-js" */),
  "component---src-pages-program-crm-korzysci-dla-wlasciciela-biura-i-dewelopera-js": () => import("./../../../src/pages/program-crm/korzysci/dla-wlasciciela-biura-i-dewelopera.js" /* webpackChunkName: "component---src-pages-program-crm-korzysci-dla-wlasciciela-biura-i-dewelopera-js" */),
  "component---src-pages-program-crm-korzysci-dla-zwierzchnika-js": () => import("./../../../src/pages/program-crm/korzysci/dla-zwierzchnika.js" /* webpackChunkName: "component---src-pages-program-crm-korzysci-dla-zwierzchnika-js" */),
  "component---src-pages-program-crm-korzysci-index-js": () => import("./../../../src/pages/program-crm/korzysci/index.js" /* webpackChunkName: "component---src-pages-program-crm-korzysci-index-js" */),
  "component---src-pages-program-crm-opinie-o-programie-imo-js": () => import("./../../../src/pages/program-crm/opinie-o-programie-imo.js" /* webpackChunkName: "component---src-pages-program-crm-opinie-o-programie-imo-js" */),
  "component---src-pages-program-crm-profesjonalny-agent-js": () => import("./../../../src/pages/program-crm/profesjonalny-agent.js" /* webpackChunkName: "component---src-pages-program-crm-profesjonalny-agent-js" */),
  "component---src-pages-program-crm-rabaty-w-portalach-js": () => import("./../../../src/pages/program-crm/rabaty-w-portalach.js" /* webpackChunkName: "component---src-pages-program-crm-rabaty-w-portalach-js" */),
  "component---src-pages-program-lojalnosciowy-polecaj-imo-js": () => import("./../../../src/pages/program-lojalnosciowy-polecaj-imo.js" /* webpackChunkName: "component---src-pages-program-lojalnosciowy-polecaj-imo-js" */),
  "component---src-pages-slownik-pojec-js": () => import("./../../../src/pages/slownik-pojec.js" /* webpackChunkName: "component---src-pages-slownik-pojec-js" */),
  "component---src-pages-strona-www-bezplatna-wersja-demo-js": () => import("./../../../src/pages/strona-www/bezplatna-wersja-demo.js" /* webpackChunkName: "component---src-pages-strona-www-bezplatna-wersja-demo-js" */),
  "component---src-pages-strona-www-js": () => import("./../../../src/pages/strona-www.js" /* webpackChunkName: "component---src-pages-strona-www-js" */),
  "component---src-pages-strona-www-korzysci-dla-dewelopera-js": () => import("./../../../src/pages/strona-www/korzysci/dla-dewelopera.js" /* webpackChunkName: "component---src-pages-strona-www-korzysci-dla-dewelopera-js" */),
  "component---src-pages-strona-www-korzysci-dla-klientow-js": () => import("./../../../src/pages/strona-www/korzysci/dla-klientow.js" /* webpackChunkName: "component---src-pages-strona-www-korzysci-dla-klientow-js" */),
  "component---src-pages-strona-www-korzysci-dla-wlasciciela-biura-js": () => import("./../../../src/pages/strona-www/korzysci/dla-wlasciciela-biura.js" /* webpackChunkName: "component---src-pages-strona-www-korzysci-dla-wlasciciela-biura-js" */),
  "component---src-pages-strona-www-korzysci-index-js": () => import("./../../../src/pages/strona-www/korzysci/index.js" /* webpackChunkName: "component---src-pages-strona-www-korzysci-index-js" */),
  "component---src-pages-strona-www-portfolio-js": () => import("./../../../src/pages/strona-www/portfolio.js" /* webpackChunkName: "component---src-pages-strona-www-portfolio-js" */),
  "component---src-pages-strona-www-strona-dedykowana-index-js": () => import("./../../../src/pages/strona-www/strona-dedykowana/index.js" /* webpackChunkName: "component---src-pages-strona-www-strona-dedykowana-index-js" */),
  "component---src-pages-strona-www-strona-dla-dewelopera-index-js": () => import("./../../../src/pages/strona-www/strona-dla-dewelopera/index.js" /* webpackChunkName: "component---src-pages-strona-www-strona-dla-dewelopera-index-js" */),
  "component---src-pages-strona-www-strona-ekspresowa-index-js": () => import("./../../../src/pages/strona-www/strona-ekspresowa/index.js" /* webpackChunkName: "component---src-pages-strona-www-strona-ekspresowa-index-js" */),
  "component---src-pages-strona-www-strona-z-projektem-dodatkowa-pomoc-techniczna-js": () => import("./../../../src/pages/strona-www/strona-z-projektem/dodatkowa-pomoc-techniczna.js" /* webpackChunkName: "component---src-pages-strona-www-strona-z-projektem-dodatkowa-pomoc-techniczna-js" */),
  "component---src-pages-strona-www-strona-z-projektem-dodatkowe-moduly-js": () => import("./../../../src/pages/strona-www/strona-z-projektem/dodatkowe-moduly.js" /* webpackChunkName: "component---src-pages-strona-www-strona-z-projektem-dodatkowe-moduly-js" */),
  "component---src-pages-strona-www-strona-z-projektem-index-js": () => import("./../../../src/pages/strona-www/strona-z-projektem/index.js" /* webpackChunkName: "component---src-pages-strona-www-strona-z-projektem-index-js" */),
  "component---src-pages-strona-www-strona-z-projektem-konfigurator-js": () => import("./../../../src/pages/strona-www/strona-z-projektem/konfigurator.js" /* webpackChunkName: "component---src-pages-strona-www-strona-z-projektem-konfigurator-js" */),
  "component---src-pages-strona-www-zamow-strone-js": () => import("./../../../src/pages/strona-www/zamow-strone.js" /* webpackChunkName: "component---src-pages-strona-www-zamow-strone-js" */),
  "component---src-pages-zamowienie-formularz-js": () => import("./../../../src/pages/zamowienie/formularz.js" /* webpackChunkName: "component---src-pages-zamowienie-formularz-js" */),
  "component---src-pages-zamowienie-payu-powrot-js": () => import("./../../../src/pages/zamowienie/payu-powrot.js" /* webpackChunkName: "component---src-pages-zamowienie-payu-powrot-js" */),
  "component---src-pages-zamowienie-potwierdzenie-js": () => import("./../../../src/pages/zamowienie/potwierdzenie.js" /* webpackChunkName: "component---src-pages-zamowienie-potwierdzenie-js" */),
  "component---src-pages-zdjecia-kategorie-js": () => import("./../../../src/pages/zdjecia-kategorie.js" /* webpackChunkName: "component---src-pages-zdjecia-kategorie-js" */)
}

